@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-image-indexer {
  &__controls {
    &__item {
      width: 222px;
      z-index: 3;
    }
  }

  &__small-button {
    color: $blue1;
    font-size: 16px;
    gap: 8px;

    img,
    svg {
      margin-right: $margin8;
    }

    &--grey {
      color: $gray4;
    }
  }

  &__list__filters {
    display: flex;
    margin-bottom: 16px;
    max-width: 1100px;
    align-items: flex-end;

    @include up-to(600px) {
      flex-wrap: wrap;
    }

    &__input {
      width: 120px;
      margin-right: 16px;

      @include up-to(600px) {
        width: calc(50% - 8px);
        margin-bottom: 8px;

        &:nth-child(2) {
          margin-right: 0;
        }
      }
    }

    &__dates {
      max-width: 320px;

      @include up-to(600px) {
        width: 100%;
        max-width: none;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &__button {
      &--last {
        margin-left: 16px;

        @include up-to(600px) {
          margin-left: 0;
          margin-top: 16px;
        }
      }

      @include up-to(600px) {
        width: 100%;
      }
    }
  }

  &__filter__input {
    width: 271px;
  }

  &__input {
    z-index: 3;
  }

  &__select_options {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
    padding-bottom: 24px;
  }

  &__left-options {
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: flex;
  }

  &__clear-button {
    align-self: flex-end;
    margin-top: auto;
  }

  &__info-box {
    width: 254px;
    align-self: stretch;
    padding: 8px 12px;
    background: #f8f8f8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon-wrapper {
    width: 32px;
    height: 32px;
    background: rgba(47, 128, 237, 0.16);
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__document-icon {
    width: 14px;
    height: 14px;
    position: relative;
  }

  &__info-text {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__record-count {
    color: black;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }

  &__subtext {
    align-self: stretch;
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  &__documents-selected {
    color: #828282;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
  }

  &_document_display_primary {
    width: '100%';
    height: '100%';
    justify-content: 'flex-start';
    align-items: 'center';
    gap: '24px';
    display: 'inline-flex';
    margin-bottom: '10px';
  }

  &__image_view_primary {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    display: inline-flex;
    margin-bottom: 10px;
  }

  &__image_view_secondary {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }

  &__image_controls {
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-end;
    display: inline-flex;
  }

  &__image_previous_next {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;
  }

  &__image-download-multipro-reindex {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;

    &__download-icon{
      color: #2F80ED;
    }

    &__switch {
      align-items: center;
      display: flex;

      &__font {
        font-size: 14px;
      }
    }
  }

  &__image_previous_next_inner_style {
    padding: 0px 0px;
    height: 26px;
    font-size: 14px;
    gap: 8px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

  &__related_image_image_view_primary {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
  }

  &__related_image_primary {
    width: 84px;
    background: rgba(79, 95, 114, 0.08);
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid rgba(79, 95, 114, 0.32);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__related_original_image {
    align-self: stretch;
    height: 127px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    cursor: pointer;
    position: relative;
    margin-bottom: 11px;

    &__primary {
      padding: 8px;
      align-self: stretch;
      box-sizing: border-box;
    }

    &__secondary {
      height: 100%;
      width: 100%;
      transition: filter 0.3s ease;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &__tick_primary {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 20px;
      height: 20px;
      background: #2F80ED;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    &__tick_secondary {
      width: 12px;
      height: 12px;
      position: relative;
    }

    &__tick-third {
      color: white;
      margin-bottom: 2px;
    }

    &__docid_doctypeid {
      align-self: stretch;
      text-align: center;
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }
  }

  &__related_image {
    align-self: stretch;
    text-align: center;
    color: rgba(79, 95, 114, 0.72);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;

    &__spin-icon {
      text-align: center;
      margin-top: 16px;
    }

    &__primary {
      align-self: stretch;
      height: 127px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      cursor: pointer;
    }

    &__secondary-one {
      padding: 8px;
      align-self: stretch;
      box-sizing: border-box;

      &__img {
        height: 100%;
        width: 100%;
        transition: filter 0.3s ease;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }

    &__secondary-two {
      align-self: stretch;
      text-align: center;
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }
  }

  &__image-to-index {
    flex: 1;
    padding: 16px 10px; /* Combines paddingTop, paddingRight, paddingBottom, and paddingLeft */
    background: rgba(79, 95, 114, 0.08);
    border-radius: 4px;
    border: 1px solid rgba(79, 95, 114, 0.32);
    align-items: flex-start;
    display: flex;
    position: relative;
    overflow: hidden;

    &__loading-primary {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__image-id-viewonly-primary {
      flex-direction: column;
      gap: 8px;
      display: inline-flex;
      z-index: 2;

      &__viewonly-primary {
        justify-content: flex-start;
        align-items: stretch;
        display: flex;
      }

      &__viewonly-secondary {
        position: relative;
        width: 73px;
        padding-left: 6px;
        padding-right: 6px;
        background: rgba(221, 63, 63, 0.16);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        display: flex;
        height: 100%;
      }

      &__viewonly-third {
        color: #C43232;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 16px;
        word-wrap: break-word;
      }

      &__id-primary {
        padding: 3px;
        background: rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: flex;
        z-index: 2;
      }

      &__id-secondary {
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        word-wrap: break-word;
      }
    }

    &__image-primary {
      overflow: visible; /* Prevents image from going outside the div */
      position: relative; /* Allows absolute positioning of the image */
      padding: 8px;
      z-index: 1;
    }

    &__image-secondary {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    &__image-controls-primary {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      display: inline-flex;
      z-index: 2;
      position: relative;

      &__button-icon-primary {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.24);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.40); // Example: Changes the background color on hover
          transform: scale(1.1); // Example: Slightly enlarges the element
          transition: transform 0.2s ease, background-color 0.2s ease; // Smooth transition for hover effects
        }
      }

      &__button-icon-secondary {
        width: 14px;
        height: 14px;
        position: relative;
      }

      &__clock-anticlock-primary {
        width: 16.19px;
        height: 14px;
        top: 0px;
        position: absolute;

        &__anticlock {
          left: -2.1px;
        }

        &__clock {
          left: -0.1px;
        }
      }

      &__plus-minus-icon-primary {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        display: flex;
      }

      &__plus-minus-icon-secondary {
        width: 12px;
        height: 12px;
        left: 1px;
        top: 1px;
        position: absolute;
      }
    }
  }

  &__image-details {
    width: 320px;
    align-self: stretch;
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgba(79, 95, 114, 0.32);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex;

    &__image-info {
      align-self: stretch;
      height: 388px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      display: flex;

      &__index-details-primary {
        align-self: stretch;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        display: inline-flex;

        &__indexedon-indexedby-primary {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          display: inline-flex;
        }

        &__indexedon-indexedby-secondary {
          justify-content: flex-start;
          align-items: flex-start;
          display: inline-flex;
        }

        &__indexedon-indexedby-third {
          color: rgba(79, 95, 114, 0.72);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          word-wrap: break-word;
        }

        &__indexedon-indexedby-fourth {
          justify-content: flex-start;
          align-items: flex-start;
          gap: 4px;
          display: inline-flex;
        }

        &__indexedon-indexedby-fifth {
          color: black;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          word-wrap: break-word;
        }
      }

      &__index-details-primary-two {
        align-self: stretch;
        height: 328px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        display: flex;

        &__secondary {
          align-self: stretch;
          height: 184px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          display: flex;
        }

        &__probill-customer {
          width: 271px;
        }
      }

      &__image-relation {
        align-self: stretch;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: inline-flex;
      }
    }

    &__image-controls-button {
      align-self: stretch;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      display: inline-flex;

      &__tick-grey {
        margin-right: 8px;
      }

      &__tick-white {
        color: white;
        margin-right: 8px;
      }

      &__pass {
        width: 138px;
      }

      &__continue {
        width: 138px;
        line-height: 20px;
        word-wrap: break-word;

        &__icon {
          margin-left: 8px;
        }
      }
    }
  }
  &__margin_left {
    margin-left: $margin8;
  }

  &__margin_right {
    margin-right: $margin8;
  }

  &__spin {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

$breakpoint: 1408px;
$selectedBarMinWidth: 400px;

.xgs-documents__invoices {
  @include responsiveTable(1408px);

  .xgs-list__controls__search {
    &>form {
      @include up-to($breakpoint) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      @include mobile() {
        grid-template-columns: 1fr;
      }
    }

    .xgs-item-form__field {
      min-width: 158px;
      flex: 1 1 158px;

      @include up-to($breakpoint) {
        min-width: 200px;
        max-width: 100%;
      }
    }

    .xgs-btn {
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 24px;
      width: 140px;

      @include mobile() {
        margin-top: 0;
        width: 100%;
      }
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @include up-to(1500px) {
      grid-template-columns: 1fr;
    }

    .xgs-list__controls {
      padding-right: 0px !important;

      @include up-to(1500px) {
        padding-right: 40px !important;
      }
    }
  }

  &__list {
    margin-top: 24px;
  }

  &__summary {
    padding-right: 40px;
    max-width: none;
    width: 100%;

    @include mobile {
      padding-right: 0;
    }

    .xgs-summary__content {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }

  &__selected-bar {
    width: 100%;

    .xgs-table__selected-bar {
      &__content {
        @include up-to($selectedBarMinWidth) {
          flex-direction: column;
        }
      }

      &__item {
        @include up-to($selectedBarMinWidth) {
          width: 100%;
        }
      }
    }
  }

  &__mobile-hidden {
    @include up-to($selectedBarMinWidth) {
      display: none;
    }
  }
}
@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

.xgs-pickup-doc-viewer-info {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
  border: 1px solid rgba(79, 95, 114, 0.32);
  border-radius: 4px;
  margin-bottom: $margin6;

  &__item {
    display: flex;
    align-items: center;
  
    span {
      color: rgba(79, 95, 114, 0.72);
    }

    img {
      margin-right: 7px;
    }
  }
}
.xgs-pickup-doc-viewer {
  display: flex;
  height: 75%;
  border: 1px solid rgba(79, 95, 114, 0.32);
  border-radius: 4px;

  .react-viewer-mask {
    background-color: rgba(79, 95, 114, 0.08);
    border-left: 1px solid rgba(79, 95, 114, 0.32);

  }
  .react-viewer-footer {
    background-color: white;
    align-items: center;
    border-left: 1px solid rgba(79, 95, 114, 0.32);
    border-top: 1px solid rgba(79, 95, 114, 0.32);
    height: 46px;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    li[data-key="scaleX"] {
      display: none;
    }
  
    li[data-key="scaleY"] {
      display: none;
    }
  }
  .react-viewer-toolbar {
    margin: 0 !important;
  }
  &__images {
    width: 112px;
    height: 100%;
    padding: 16px;
    background-color: rgba(79, 95, 114, 0.08);

    &__item {
      margin-bottom: 16px;
      cursor: pointer;

      img {
        width: 100%;
      }
      p {
        margin: 0px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }

      &:hover {
        color: $blue1;
      }

      &--selected {
        color: $blue1;
      }
    }
  }

  &__buttons {
    margin-top: $margin6;
    display: flex;
    gap: 16px;
    button {
      width: 160px;
      @include up-to(600px) {
        width: 100%;
      }
    }
  }
}
@import "../../../../sass/variables.module.scss";
@import "../../../../sass/mixins.scss";

.xgs-inbound-pickups-filter {
  max-width: 1124px;
  width: 94%;
  margin-bottom: 24px;

  &__controls {
    display: flex;
    flex-direction: row;
    // max-width: 1124px;
    // align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    column-gap: 16px;

    &__item {
      position: relative;
      width: 200px;
      flex-shrink: 0;

      &:last-child {
        margin-right: 0;
      }

      @include up-to(920px) {
        width: calc(50% - 20px);
      }

      @include up-to(470px) {
        width: 100%;
      }
    }

    &__large-item {
      min-width: 200px;
      position: relative;
      flex-shrink: 0;

      @include up-to(470px) {
        width: 100%;
      }
    }

    &__double-item {
      display: flex;
      flex-shrink: 0;
      width: fit-content;
      min-width: 150px;
    
      &__unit {
        width: 200px;

        &:not(:last-child) {
          margin-right: 20px;
        }

        @include up-to(680px) {
          width: calc(50% - 10px);
        }
      }

      @include up-to(920px) {
        width: calc(50% - 20px);
      }

      @include up-to(470px) {
        margin-right: 0;
        width: 100%;
      }
    }

    &__date {
      label {
        font-size: 14px;
        line-height: 24px;
      }
      width: 100px;
    }
  }

  &__buttons {
    width: 440px;
    align-self: flex-end;
    
    &--small {
      width: 300px;
    }
    @include up-to(920px) {
      margin-top: 0;
      width: 60%;
    }

    @include up-to(556px) {
      margin-top: 0;
      width: 100%;
    }

    @include up-to(400px) {
      margin-bottom: 16px;
    }

    .xgs-btn {
      width: calc(50% - 20px);

      &:not(:last-child) {
        margin-right: 20px;
      }

      @include up-to(470px) {
        width: calc(50% - 10px);
      }

      @include up-to(400px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  &__bottom-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__base-filters {
    display: flex;
    align-items: center;
    margin: -8px 0 8px 48px;
    padding: 8px 10px;
    color: $gray2;
    background-color: $gray7;
    border-radius: $border-radius;
    font-size: 14px;
    letter-spacing: 0.4px;
    word-break: break-word;
    max-width: 260px;

    @include up-to(670px) {
      margin-left: 16px;
    }

    @include up-to(594px) {
      margin: -8px auto 8px auto;
    }

    @include up-to(400px) {
      margin-top: -16px;
    }

    &__icon {
      color: $orange;
      margin-right: 8px;
      width: 20px !important;
      height: auto;
    }

    &__text {
      width: calc(100% - 28px);
    }
  }

  &__additional-group {
    display: inline-block;
    background-color: $gray7;
    padding: 8px 10px;
    border-radius: $border-radius;
    margin-bottom: 8px;
    font-size: 14px;
    word-break: break-word;

    @include up-to(450px) {
      text-align: center;
    }

    &__name {
      font-weight: bold;
      text-transform: uppercase;
    }

    &__link-desktop-part {
      @include up-to(356px) {
        display: none;
      }
    }
  }

  &__download-csv {
    position: absolute;
    top: 42px;
    left: 980px;

    @include up-to(1180px) {
      left: auto;
      right: 110px;
    }

    @include up-to(768px) {
      right: 80px;
    }

    @include up-to(400px) {
      position: relative;
      top: auto;
      right: auto;
      margin-bottom: 24px;
      text-align: center;
    }

    &__spinner {
      margin-left: -4px !important;
    }
  }
  
}

@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-pickup-assignment {
  &__row {
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
    padding-right: $page-content-margin;

    @include up-to(768px) {
      flex-wrap: wrap;
    }
  }

  &__block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray3;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    padding-right: $page-content-margin;

    @include up-to(1550px) {
      flex-wrap: wrap;
    }
  }  

  &__collapsible-block-header {
    display: flex;    
    align-items: center;
    justify-content: space-between;
    color: $gray3;
    font-size: 16px;
    line-height: 20px;   
    padding-right: $page-content-margin;

    @include up-to(1260px) {
      flex-wrap: wrap;
    }

    @include mobile() {      
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__collapsible-block-header-controls {
    display: flex;
    align-items: center;
    gap: 24px;

    @include mobile() {      
      margin-top: 16px;
    }
  }

  &__block-title {
    font-family: "Roboto-Bold"; 
  }

  &__dispatch-tools {
    display: flex;
    width: 100%;

    @include up-to(768px) {
      flex-wrap: wrap;
      gap: 24px;
    }
  }

  &__map {
    flex-shrink: 0;
    width: calc(100% - 300px - 24px); //560px;
    max-width: 70%;
    height: 324px;

    @include up-to(768px) {
      width: 100%;
      max-width: none;
    }

    .xgs-map__container {
      height: 324px;
    }
  }

  &__map-legend {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__map-legend-title {
    @include mobile() {
      display: none;
    }
  }

  &__drivers {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    min-width: 300px;
    width: 100%;
    height: 324px;
    max-height: 324px;
    overflow-y: hidden;

    @include up-to(768px) {
      min-width: 0;
    }

    &__table__header {
      display: none !important;
    }

    &__list {
      flex-shrink: 1;
      height: 100%;

      &--centered-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--with-button {
        padding-bottom: 64px;
      }

      .xgs-table {
        overflow-y: scroll;
      }

      &__item {
        position: relative;
        padding: 0 8px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px 16px;

        &__property {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: 4px;

            img {
              display: block;
            }
          }

          &__text {
            font-size: 14px;
            line-height: 20px;
            min-width: 60px;
            max-width: 90px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &--driver-name {
              width: 200px;
              max-width: 200px;
            }
          }
        }
      }
    }

    &__not-selected {
      border-radius: $border-radius;
      background: $gray7;
      padding: 152px 90px 148px 90px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      color: $gray3;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
    }

    &__button {
      flex-shrink: 0;
      position: absolute;
      bottom: 0;
      padding: 16px;
      width: 100%;
      background-color: white;
      border-top: 1px solid $gray5;
      border-radius: 0 0 $border-radius 0;

      .xgs-btn {
        width: 100%;
      }

      &__reschedule {
        padding-left: 2px;
        padding-right: 2px;

        @include up-to(380px) {
          height: fit-content;
        }
      }
    }

    .xgs-pickup-assignment__block-header {
      padding-right: 0;
    }

    &__missed-pickup {
      padding: 16px 40px;
      text-align: center;
      color: $gray3;
      line-height: 24px;
      font-size: 18px;
    }
  }

  &__orders {
    @include responsiveTable(1480px);

    &__missed-label {      
      margin-left: 8px;
    }

    &__time-expired {
      color: $red;
    }

    &__filters {
      display: flex;
      gap: 24px;
      justify-content: space-between;
      margin-left: 8px;
      flex-grow: 1;      

      @include up-to(1550px) {
        width: 100%;
        margin-top: 16px;
        flex-wrap: wrap;
      }

      &__block {
        display: flex;
        gap: 24px;
      }

      &__control {
        display: flex;
        align-items: center;

        @include up-to(560px) {
          width: 100%;
        }

        &__toggle {
          margin-right: 8px;
          height: 20px;
        }

        &__label {
          font-size: 14px;
          line-height: 20px;
          color: black;
        }

        &__input {
          width: 225px;

          &--wide {
            width: 565px;

            @include up-to(560px) {
              width: auto;
            }
          }

          &.xgs-labeled-input.row {
            color: black;

            label {
              width: fit-content;
              max-width: 90px;
              margin-right: 8px;
              padding-right: 0;
              line-height: 20px;
            }

            & > div {
              width: calc(100% - 98px);
            }
          }

          &.xgs-labeled-input.row {
            @include up-to(560px) {
              width: 100%;
            }
          }
        }

        &__button {
          width: 80px;
          margin-left: 8px;
        }
      }

      .xgs-select__multi-value__label > div > div:nth-child(2) {
        display: none;
      }

      .xgs-select__control {
        max-height: 32px;

        @include up-to(550px) {
          max-height: none;
        }
      }

      .xgs-select__value-container.xgs-select__value-container--is-multi {
        padding: 0;
      }
    }

    &__selected {
      box-shadow:
        inset 0 -1px 0 #2F80ED,
        inset 0 1px 0 #2F80ED;
      background: #DDEBFF;

      .xgs-pickup-assignment__orders__status {
        opacity: 1;
      }
    }
  }

  .td {
    line-height: 20px;
  } 

  &__popup {
    max-width: calc(360px - 24px - 24px);

    &__image {
      text-align: center;
      padding: 24px 0 49px 0;
    }

    &__text {
      color: black;
      font-size: 14px;
      line-height: 20px;

      &--centered {
        text-align: center;
      }
    }
  }

  &__outer-pickup-block {
    margin: 36px auto 0 auto;
    min-width: 320px;
    width: 50%;
    max-width: 640px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;

    &__button {
      margin: 16px 0 48px 0;
    }
  }
}

@import "./../sass/variables.module.scss";
@import "./../sass/mixins.scss";

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.ttf");
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
  min-height: 100vh; // fix iOS 15 black area at the bottom
}

body {
  margin: 0;
  font-family: "Roboto-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
}

.xgs-site {
  display: flex;
  flex-direction: column;

  &--menu-opened {
    @include mobile {
      position: fixed;
      width: 100vw;
      max-width: 100vw;
      overflow-x: hidden;
    }

    @include up-to(600px) {
      overflow-y: hidden;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  &__content {
    position: relative;
    height: 100%;
    min-height: calc(100vh - #{$header-height});
    width: 100%;
    overflow-x: auto;
    padding: $margin6 0 0;

    &--driver {
      max-width: 980px;
      margin: 0 auto;

      .xgs-site__content-container__title {
        display: none;
      }
    }
  }

  .bold {
    font-family: "Roboto-Bold";
  }

  .clickable {
    cursor: pointer;
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }

  .flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  .flexbox {
    display: flex;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .gap-16 {
    gap: 16px;
  }

  &__box__link {
    font-size: 14px;
    line-height: 16px;
    color: $gray3;

    a {
      color: $blue1;
      text-decoration: none;
    }
  }
}

.Toastify__toast--error {
  background-color: $red;
}

.display-none {
  display: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-light {
  color: #777777;
}

.text-blue {
  color: $blue1;
}

.cursor-pointer {
  cursor: pointer;
}

.block-center {
  margin: 0 auto;
}

.blue-link {
  color: $blue1;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.red-link {
  cursor: pointer;
  color: $red;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.white-link {
  cursor: pointer;
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.underline-link {
  &:hover {
    text-decoration: underline;
  }
}

.xgs-list {
  &__controls {
    max-width: 1124px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;

    @include mobile {
      margin-top: 0;
    }

    @include up-to(450px) {
      width: 100%;
    }

    &__search,
    &__actions {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    &__search {
      gap: $margin7;

      @include up-to(510px) {
        padding-right: 32px;
      }
    }

    &__clear-button {
      width: 62px;
    }

    &__search__input {
      width: 280px;
      flex-shrink: 0;

      &--narrow {
        width: 140px;
      }

      @include up-to(560px) {
        width: 220px;
      }

      @include up-to(510px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &__date-range {
      max-width: 200px;

      @include up-to(510px) {
        max-width: none;
        width: 100%;
      }
    }

    &__button {
      &:not(:last-child) {
        margin-right: $margin7;

        @include up-to(450px) {
          margin-right: 0;
        }
      }

      @include up-to(640px) {
        width: 100px;
      }

      @include up-to(510px) {
        margin-top: $margin8;
      }

      @include up-to(450px) {
        width: 100%;
      }
    }
  }

  &__table-wrapper {
    max-width: 1448px;
    height: calc(100vh - 242px);

    @include mobile {
      height: calc(100vh - 264px);
    }

    @include up-to(642px) {
      height: calc(100vh - 308px);
    }
  }

  &__table {
    height: 100%;
  }

  &--full-width {
    .xgs-list__controls {
      margin-top: 0;
      margin-bottom: 24px;
      padding-right: $page-content-margin;
      max-width: none;
      width: 100%;

      @include up-to($mobile-width) {        
        padding-right: 24px;
      }  
    }

    .xgs-list__controls__search {      
      width: 100%;

      @include up-to($mobile-width) {        
        height: auto;
        padding-right: 0;
      }      
    }

    .xgs-list__controls__search-form {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      height: 100%;       
  
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .xgs-list__table-wrapper {
      width: 100%;
      max-width: 100%;  
      height: auto;    
    }
  }
}

.xgs-sidebar__details {
  &__name-block {
    display: flex;
    align-items: flex-start;
    margin-bottom: $margin6;
  }

  &__avatar {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 64px;
    background-color: $gray4;
    color: white;
    text-align: center;
    margin-right: $margin7;
    margin-bottom: $margin7;
    border-radius: $border-radius;
  }

  &__name,
  &__title {
    word-break: break-all;
    font-size: 24px;
    line-height: 28px;
  }

  &__title {
    color: $gray4;
    font-size: 14px;
    margin-bottom: 6px;
  }

  &__rows {
    line-height: 1.7;
    font-size: 14px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4px;

    @include up-to(500px) {
      margin-bottom: 8px;
    }
  }

  &__label {
    width: 160px;
    flex-shrink: 0;

    &:not(.xgs-sidebar__details__label--fixed) {
      @include up-to(500px) {
        width: 100%;
      }
    }
  }

  &__value {
    word-break: break-word;
    width: calc(100% - 160px);

    @include up-to(500px) {
      width: 100%;
    }
  }
  
  &__button {
    margin-left: 16px;
  }
}

.xgs-form {
  &__container {
    max-width: 540px;
  }

  &__field {
    position: relative;
    margin-bottom: 12px;
    min-height: 80px;
    // max-width: 410px;
    width: 100%;

    &--no-min-height {
      min-height: 0;
    }

    &--gray-bg {
      padding: 8px;
      background-color: $gray7;
      border-radius: $border-radius;
    }

    &--short {
      width: 200px;

      @include up-to(540px) {
        width: 100%;
      }
    }

    &__input {
      &--error {
        .xgs-input__field {
          border-color: #cc0000;
        }
      }
    }

    &__notes {
      font-size: 12px;
      margin-bottom: 16px;
      color: $gray3;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 24px;

    span {
      color: $red;
    }
  }  

  &__radio {
    display: block;
    font-size: 14px;
    width: 100%;

    label {
      margin-right: 12px;
    }
  }

  &__textarea {
    border: 1px solid rgba($gray8, 0.32);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    transition: all 100ms;
    font-size: 14px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2pt $supporting_blue_40 !important;      
    }

    &:disabled {
      background-color: rgba($gray8, 0.04);
      color: rgba($gray8, 0.32);
    }
  }

  &__address-block {
    padding: 4px 8px;
    margin: 0 -8px;
    border-radius: $border-radius;
    background-color: $gray7;

    &__container {
      position: relative;
      max-width: 400px;
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 1.2;

      @media (max-width: 510px) {
        margin-left: 0;
        width: 100%;
      }

      .xgs-bol__field {
        margin-bottom: 16px;
      }
    }

    .xgs-labeled-input {
      position: relative;
      margin-bottom: 16px;
    }
  }

  &__buttons {
    margin-top: 36px;

    .xgs-btn {
      @include up-to(600px) {
        width: 100%;
        margin-right: 0;
      }

      &:not(:last-child) {
        margin-right: 16px;

        @include up-to(600px) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }

  &__loading {
    margin-top: 16px;
  }
}

.ReactModal__Content.ReactModal__Content--after-open {
  @include up-to(480px) {
    padding: 37px 16px 24px !important;
  }
}

.xgs-gallery {
  z-index: 1002 !important;
  background-color: rgba(0, 0, 0, 0.8);

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 52px;
    z-index: 1003;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;

    &__text {
      margin: 16px 0 0 16px;
    }

    &__close-icon {
      position: absolute;
      top: 2px;
      right: 24px;
      cursor: pointer;
      width: 48px;
      height: 48px;
    }
  }

  &__prev,
  &__next {
    z-index: 1003;
    cursor: pointer;
    position: absolute;
  }

  &__prev__icon,
  &__next__icon {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 4px;
    width: 32px !important;
    height: 48px !important;
  }

  &__prev {
    left: 8px;
  }

  &__next {
    right: 8px;
  }
}

.react-tabs__tab {
  &:last-child:only-child {
    border-radius: 4px;
  }
}

.xgs-gray-area {
  background-color: $gray_4;
  border-radius: $border-radius;
  padding: 16px;
  margin-left: -16px;
  width: calc(100% + 16px);

  &__header {
    color: $gray3;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  &__label {
    color: $gray3;
    font-size: 12px;
    line-height: 20px;
  }

  &__content {
    font-size: 14px;
  }
}

.xgs-accordion {
  position: relative;
  min-height: 24px;

  &__toggle {
    color: $blue1;
    font-size: 14px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--opened {

    }

    &__icon {
      margin-left: 8px;
    }

    span {
      text-decoration: underline;
    }
  }

  &__content {
    opacity: 0;
    max-height: 0;
    transition: all 0.7s ease;

    &--opened {
      padding-top: 16px;
      opacity: 1;
      max-height: fit-content;
    }
  }
}

.xgs-wizard {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: $margin3;

  @include up-to(620px) {
    flex-wrap: wrap;

    .xgs-steps-milestones {
      margin-top: 24px;
    }
  }

  .xgs-steps-milestones__item__data span {
    font-weight: bold;
    letter-spacing: 0.2px;
  }

  &__step {
    &__header {
      position: relative;
      font-size: 16px;
      line-height: 19px;
      color: $gray3;
      margin-bottom: $margin7;
    }

    &__buttons {
      // margin-right: 16px;

      @include up-to(810px) {
        width: 100%;
        margin-bottom: 16px;
        margin-right: 0;
      }

      .xgs-btn {
        width: 160px;

        @include up-to(640px) {
          width: 140px;
        }

        @include up-to(620px) {
          width: 160px;
        }

        @include up-to(540px) {
          width: 100%;
        }
      }

      .xgs-btn + .xgs-btn {
        margin-left: 16px;

        @include up-to(540px) {
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }
}

.xgs-upload {
  &__notes {
    color: $gray2;
    margin-top: 4px;
    width: 100%;
  }

  &__area {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: $gray6;
    padding: 16px;
    max-width: 540px;
    border-radius: $border-radius;
    cursor: pointer;
    font-size: 14px;
    text-align: center;

    @include up-to(330px) {
      padding: 12px;
    }

    &__error {
      color: $red;
      margin-top: 16px;
      width: 100%;
    }
  }
}

.xgs-status-label {
  display: inline-block;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
}

.xgs-animation {
  &__shaking {
    animation: shaking 1.2s cubic-bezier(.36,.07,.19,.97) both;
    -webkit-animation: shaking 1.2s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &__fade-out {
    animation: fadeout 1s cubic-bezier(.36,.07,.19,.97) both;
    -moz-animation: fadeout 1s cubic-bezier(.36,.07,.19,.97) both;
    -webkit-animation: fadeout 1s cubic-bezier(.36,.07,.19,.97) both;
    -o-animation: fadeout 1s cubic-bezier(.36,.07,.19,.97) both;
  }
}

.form-items {
  height: auto;
  width: 100%;

  .xgs-labeled-input {
    margin-top: 24px;
    font-size: 14px;

    label {
      height: 24px;
    }
  }
}

.xgs-space {
  &__top {
    &__48 {
      margin-top: 48px;
    }
  }
}

.xgs-line {
  margin: 24px 0;
  height: 1px;
  width: 100%;
  background-color: #E0E0E0;
}

.mobile-hidden {
  @include mobile() {
    display: none;
  }
}

@keyframes shaking {
  10%, 90% {
    transform: translate3d(-1px, -1px, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, -2px, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 2px, 0);
  }
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";
$breakpoint: 1408px;
$selectedBarMinWidth: 650px;

.remittance-invoices {
  &__list {
    @include responsiveTable(1400px);
  }

  .xgs-list__controls__search {
    &>form {
      @include up-to($breakpoint) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      @include mobile() {
        grid-template-columns: 1fr;
      }
    }

    .xgs-item-form__field {
      min-width: 158px;
      flex: 1 1 158px;

      @include up-to($breakpoint) {
        min-width: 200px;
        max-width: 100%;
      }
    }

    .xgs-list__controls__date-range {
      max-width: none;
    }

    .xgs-btn {
      margin-top: 24px;

      @include mobile() {
        margin-top: 0;
        width: 100%;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 8px;

    @include mobile() {
      flex-direction: column;
    }
  }

  &__search-button {
    width: 100%;
    min-width: 99px;
  }

  &__clear-button {
    width: 62px;

    @include mobile() {
      width: 100%;
    }
  }

  &__selected-bar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 40px;

      @include up-to($selectedBarMinWidth) {
        flex-direction: column;
      }
    }

    &__warning {
      display: flex;
      gap: 16px;

      &__icon {
        color: $blue1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__main {
        font-family: "Roboto-Bold";
      }
    }

    .xgs-table__selected-bar__item {
      @include up-to($selectedBarMinWidth) {
        width: 100%;
      }

      &:not(&:last-child) {
        @include up-to($selectedBarMinWidth) {
         margin-bottom: 16px;      
        }
      }

      .xgs-btn {
        min-width: 168px;

        @include up-to($selectedBarMinWidth) {
          width: 100%;
        }
      }
    }
  }

  &__details,
  &__email {
    margin-bottom: 40px;
    
    &__header {
      font-family: "Roboto-Bold";
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &__error {
      font-size: 14px;
      color: $supporting-red;
      background-color: $supporting_red_8;
      border: 1px solid $supporting-red;
      border-radius: $border-radius;
      padding: 16px;

      &__title {
        font-family: "Roboto-Bold";
        font-size: 18px;
        margin-bottom: 8px;
        height: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  &__email {

    &__content,
    &__headers {
      padding: 24px;
      border-radius: $border-radius;
      border: 1px solid $gray_32;
      margin-bottom: 24px;
    }

    &__headers {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__row {
        display: flex;
        gap: 24px;

        @include mobile() {
          flex-direction: column;
          gap: 8px;
        }
      }

      &__item {
        display: flex;

        @include mobile() {
          flex-direction: column;
        }
      }
    }

    &__content {
      overflow-x: auto;
    }

    &__download {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;

      .blue-link {
        &:hover {
          color: $blue4;
        }
      }
    }

    &__subtitle {
      font-family: "Roboto-Bold";
    }

    &__attachments {
      display: flex;
      gap: 24px;
      margin-bottom: 12px;
      flex-wrap: wrap;
    }

    &__attachment {
      display: flex;
      gap: 8px;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      max-width: 250px;

      &:hover {
        &__link {
          color: $blue1;
        }
      }

      &__content {
        min-width: 0;        
      }

      &__link {
        font-family: "Roboto-Bold";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      background-color: $supporting_blue_16;
      border-radius: $border-radius;
      color: $blue1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    &__label {
      color: $gray_72;
    }

    &__tooltip {
      font-size: 14px;
    }
  }
}
@import "./../../sass/variables.module.scss";

.xgs-field-range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__divider {
    display: flex;
    width: $margin5;
    align-items: center;
    justify-content: center;
    color: $gray4;
  }

  &__field {
    width: 100%;
  }
}
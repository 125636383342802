@import "./../../sass/variables.module.scss";

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    margin: 0 0 24px 0;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    position: relative;
    list-style: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    color: $gray1;
    box-sizing: border-box;
    border: 1px solid $blue1;
    border-right: none;
    height: 32px;
    width: 127px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-radius: 4px 0px 0px 4px;      
    }

    &:last-child {
      border-right: 1px solid $blue1;
      border-radius: 0px 4px 4px 0px;
    }

    &--selected {
      background-color: #2F80ED;
      color: white;
      cursor: inherit;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:hover:not(.react-tabs__tab--selected) {
      background: rgba(47, 128, 237, 0.16);
    }

    &:focus:not(.react-tabs__tab--selected) {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;

      .xgs-error-message {
        margin-bottom: 24px;
      }
    }
  }

  &--scrollable {
    .react-tabs__tab-list-wrapper {
      width: 100%;
      overflow-x: auto;
    }
  
    .react-tabs__tab-list {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 16px;
      max-width: 100%;
      gap: 4px;
    }

    .react-tabs__tab {
      height: 24px;
      border-radius: 4px;
      padding: 4px 8px;
      width: auto;
      color: $gray8;
      border: 1px solid $gray8;

      &--selected {
        background-color: $gray8;
        color: white;
      }
    }
  }
}

@import "./../../sass/variables.module.scss";
@import "./../../sass/mixins.scss";

.xgs-header {
  position: relative;
  height: $header-height;
  background-color: $header-back;
  flex-shrink: 0;

  &__content {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    height: $header-height;
    background-color: $header-back;
    border-bottom: 1px solid $header-border-bottom-color;
    overflow: hidden;
    z-index: 4;

    @include up-to(600px) {
      justify-content: space-between;
    }
  }

  a {
    text-decoration: none;
  }

  &__start {
    flex-shrink: 0;
    width: $side-menu-width-desktop;
    height: $header-height;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      width: $side-menu-width-tablet;
    }

    @include up-to(600px) {
      width: auto;
    }

    .xgs-logo {
      display: block;
      width: 90%;
      margin: 0 auto;
    }

    &.xgs-header__signin {
      width: 100vw;
      justify-content: center;

      @include up-to(600px) {
        width: 0;
      }
    }

    &.right-border {
      border-right: 1px solid $gray5;

      @include up-to(600px) {
        border-right: none;
      }
    }
  }

  &__end {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;

    @include up-to(600px) {
      flex: none;
    }
  }

  .xgs-logo {
    height: 54px;
    width: auto;
    max-width: 100%;

    @include mobile {
      width: 100px;
      height: auto;
      max-height: 54px;
    }
  }

  &__menu-icon {
    &__wrapper {
      display: none;
      width: 32px;
      margin: 0 24px;

      @include tablet {
        margin: 0 32px;
      }

      @include up-to(1025px) {
        display: block;
      }
    }

    &__image {
      width: 32px;
      cursor: pointer;
    }
  }

  &__selector {
    @include up-to(600px) {
      display: none;
    }
  }

  &__logo {
    width: 100%;

    @include up-to(600px) {
      display: none;
    }
  }

  &__mobile-logo {
    display: none;

    @include up-to(600px) {
      display: block;
    }
  }

  .xgs-stage-banner {
    @include up-to(768px) {
      display: none;
    }
  }

  &__feedback {
    position: fixed;
    top: $header-height;
    right: 34px;
    z-index: 21;
  }  
}
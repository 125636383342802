@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-invoice-generator {

  width: 368px;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;

  &__instruction {
    align-self: stretch;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

  &__input-area {
    align-self: stretch;
    height: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: flex;
    margin-bottom: $margin4;

    &__name-primary {
      align-self: stretch;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 4px;
      display: inline-flex;
    }

    &__name-secondary {
      flex: 1 1 0;
      height: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 2px;
      display: flex;
    }

    &__name-third {
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &__name-fourth {
      color: black;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }

    &__text-area {
      width: 368px !important;
      height: 64px !important;
    }
  }

  &__switch-primary {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
  }

  &__switch-secondary {
    margin-left: 8px;
    font-size: 14px;
  }

  &__checkbox-collection {
    align-self: stretch;
    height: 80px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    margin-bottom: $margin4;

    &__primary {
      align-self: stretch;
      color: black;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }

    &__secondary {
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      gap: 90px;
      display: inline-flex;
    }

    &__third {
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      gap: 83px;
      display: inline-flex;
    }
  }

    &__limit-reached {
      margin-top: 8px;
      font-size: 14px;
      color: $red;
    }
}

.xgs-invoice-viewer {

  &__button-collection {
    position: relative;

    &__download-button {
      position: absolute;
      top: -55px;
      right: 222px;
    }
    
    &__create-button {
      position: absolute;
      top: -55px;
      right: 0px;
      width: 210px;
    }

    &__margin-eight {
      margin-right: 8px;
    }
  }

  &__invoice-view {
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;

    &__probill-thumbnail {
      width: 105px;
      align-self: stretch;
      padding: 8px;
      background: rgba(79, 95, 114, 0.08);
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid rgba(79, 95, 114, 0.32);
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      display: inline-flex;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__header {
      align-self: stretch;
      text-align: center;
      color: rgba(79, 95, 114, 0.72);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }

    &__spinner-loader {
      text-align: center;
      margin-top: 16px;
    }

    &__thumbnail {
      align-self: stretch;
      height: 127px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      cursor: pointer;
      position: relative;
    }

    &__img-primary {
      padding: 8px;
      align-self: stretch;
      box-sizing: border-box;

      &__img {
        height: 100%;
        width: 100%;
        transition: filter 0.3s ease;
        border-radius: 4px;
      }
    }

    &__checkbox {
      position: absolute;
      top: 8px;
      left: 9px;
      width: 16px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      align-self: center;
    }

    &__thumbnail-info {
      align-self: stretch;
      text-align: center;
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }
    
    &__related-thumbnail {
      width: 105px;
      padding: 8px;
      background: rgba(79, 95, 114, 0.08);
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid rgba(79, 95, 114, 0.32);
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      display: inline-flex;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__primary {
      flex: 1;
      align-self: stretch;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      display: inline-flex;
    }

    &__invoice-view-window {
      flex: 1;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 16px;
      padding-bottom: 16px;
      flex-grow: 1;
      flex-basis: 0;
      background: rgba(79, 95, 114, 0.08);
      border-radius: 4px;
      border: 1px solid rgba(79, 95, 114, 0.32);
      align-items: flex-start;
      display: flex;
      position: relative;
      overflow: hidden;
      width: 100%;

      &__id-primary {
        padding: 3px;
        background: rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: flex;
        z-index: 2;
      }

      &__id-secondary {
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        word-wrap: break-word;
      }

      &__primary{
        overflow: visible; // Prevents image from going outside the div
        position: relative; // Allows absolute positioning of the image
        padding: 8px;
        z-index: 1;
      }

      &__spinner-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      &__image-controls {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        display: inline-flex;
        z-index: 2;
        position: relative;

        &__plus-minus {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          display: flex;
        }

        &__primary {
          width: 32px;
          height: 32px;
          background: rgba(0, 0, 0, 0.24);
          border-radius: 4px;
          overflow: hidden;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 0, 0, 0.40); // Example: Changes the background color on hover
            transform: scale(1.1); // Example: Slightly enlarges the element
            transition: transform 0.2s ease, background-color 0.2s ease; // Smooth transition for hover effects
          }
        }

        &__secondary {
          width: 14px;
          height: 14px;
          position: relative;
        }

        &__anticlock-icon {
          width: 16.19px;
          height: 14px;
          left: -2.10px;
          top: 0px;
          position: absolute;
        }

        &__clock-icon {
          width: 16.19px;
          height: 14px;
          left: 0.71px;
          top: 0px;
          position: absolute;
        }

        &__plus-minus-icon {
          width: 12px;
          height: 12px;
          left: 1px;
          top: 1px;
          position: absolute;
        }
      }
    }
  }
}
@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-location-popup {
  &-content {
    padding: 24px;

    @include up-to(740px) {
      position: absolute;
      left: 0 !important;
      max-width: 100%;
    }
  }

  &__header {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__map {
    width: 100%;
    height: calc(100% - 42px);
  }
}

@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-shipment-details {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  &__main-container {
    max-width: 100%;
  }

  &__header {
    max-width: 928px;

    .xgs-site__content-container-header__bar {
      flex-grow: 1;

      width: 100%;
      margin-top: 16px;
    }

    &__status {
      max-width: 250px;

      @include up-to(1212px) {
        max-width: 400px;
      }

      @include up-to(680px) {
        max-width: 250px;
      }

      @include up-to(420px) {
        max-width: none;
        width: 100%;
      }
    }
  }

  &__subscribe {
    &__button {
      cursor: pointer;
      text-align: center;
      margin-right: 8px;

      &--unsubscribe {
        color: $red;
      }

      &__icon {
        font-size: 18px;
      }

      &__label {
        font-size: 11px;
        line-height: 12px;
        margin-top: 7px;
      }
    }

    &__confirmation__text {
      line-height: 1.4;
    }
  }

  &__share {
    &__clipboard {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 36px 0;

      &__field {
        width: calc(100% - 32px);
      }

      &__button {
        cursor: pointer;
        color: $blue2;
        margin-bottom: 6px;

        &:hover {
          color: $blue3;
        }
      }
    }

    &__hr {
      border: 1px dashed $gray6;
      margin: 32px 0;
    }
  }

  &__grid {
    max-width: 928px;

    &__double-column {
      flex-grow: 1;
      max-width: calc(100% / 3 * 2 - 16px);

      @include up-to(1140px) {
        max-width: calc(100% / 2 - 8px);
      }

      @include up-to(692px) {
        width: 100%;
        max-width: none;
      }

      .xgs-info-block__container {
        padding: 6px;
      }

      &__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        &--empty {
          flex-grow: 1;
        }
      }

      .xgs-labeled-value {
        padding: 4px;
      }
    }
  }

  .xgs-info-block {
    min-width: 254px;

    @include up-to(692px) {
      width: 100%;
    }    
  }

  &__pay-invoice {
    width: 220px;
    padding-right: 24px;
    margin-right: 0;

    @include up-to(710px) {
      width: 100%;
      padding-right: 0;
    }

    .xgs-btn {
      margin-left: 16px;
      width: 96px;
      font-size: 14px;

      @include up-to(642px) {
        width: 100%;
        flex-shrink: 1;
        margin-left: 16px;
      }
    }
  }

  &__no-pod {
    width: 572px;
    text-align: center;

    @include up-to(692px) {
      width: 100%;
    }
  }

  &__tabs {
    margin: 36px 0;
    max-width: 895px;

    &--single {
      .react-tabs__tab:last-child {
        border-radius: 4px;
      }
    }

    &__no-data {
      text-align: center;
      font-size: 14px;
      padding: 32px;
      line-height: 1;
      color: $gray3;
    }

    &__error {
      text-align: center;
      font-size: 14px;
      padding: 32px;
      line-height: 1;
      color: $red;
    }

    .react-tabs__tab-list {
      margin-bottom: 4px;
    }

    .react-tabs__tab-panel {
      margin-top: 12px;
      background: $gray6;
      max-height: 320px;
      overflow-y: auto;
    }

    .react-tabs__tab {
      @include up-to(470px) {
        width: 100%;
        border: 1px solid #2F80ED !important;
        border-radius: 0;

        &:first-child {
          border-radius: 4px 4px 0 0;
        }

        &:last-child {
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }

  &__shipment-tabs {
    .xgs-table {
      min-width: 895px !important;
    } 
  }

  &__comments {

    .xgs-table .tr {
      align-items: center;
    }

    &__time {
      color: rgba(79, 95, 114, 0.72);
    }
  }

  &__add-comment-button {
    width: 211px;
    margin-top: 12px;
  }

  &__dock-log {
    max-height: 304px;
    height: 100%;
    min-height: 80px;
  }

  &__status-history {
    max-height: 304px;
    height: 100%;
  }

  &__assets {
    .xgs-shipment-details__tabs {
      margin: 0 0 16px 0;
    }
  }

  .xgs-form__field__notes {
    margin-bottom: 0;

    ul {
      padding: 0 0 0 16px;
      margin: 0;
    }

    li + li {
      margin-top: 4px;
    }
  }

  &__ai-banner {
    max-width: 904px;
    margin-bottom: 29px;
    display: flex;
    flex-wrap: wrap;

    &__content {
      flex: 1 1 500px;
    }
  }

  &__tracking-history-button {
    display: none;
    cursor: pointer;
    color: #828282;

    @include up-to(1650px) {
      display: block;
    }

    &__icon {
      padding-top: 1px;

      svg {
        height: 18px;
        width: 18px;
        margin-left: 8px;
      }
    }

    &__label {
      font-size: 11px;
      line-height: 12px;
      margin-top: 3px;
    }
  }

  &__tracking-history-container {
    &--wide-screen {
      @include up-to(1650px) {
        display: none;
      }
    }

    &--narrow-screen {
      display: none;

      @include up-to(1650px) {
        display: block;
      }
    }
  }

  &__tracking-history {
    max-width: 420px;
    border-radius: $border-radius;
    background-color: #EFEFEF;

    &--in-sidebar {
      max-width: none;
      border: 0;
      background-color: white;

      .xgs-shipment-details__tracking-history__paired-values {
        padding: 24px 0;
        margin: 0 24px;
        border-bottom: 1px solid rgba(79, 95, 114, 0.32);
      }

      .xgs-shipment-details__tracking-history__content {
        padding: 24px;
      }
    }

    &__paired-values {
      padding-top: 14px;
      margin: 0 14px;

      &__item {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        font-size: 14px;
        line-height: 20px;

        &__label {
          color: rgba(79, 95, 114, 0.72);
        }

        &__value {
          text-align: right;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &__content {
      padding: 14px;
    }

    &__no-events {
      text-align: center;
      padding: 16px;
      color: rgba(79, 95, 114, 0.72);
    }

    &__spinner {
      margin: 16px;
    }

    .xgs-stepped-progress {
      margin: 0;

      &__step {
        &:last-child {
          padding-bottom: 0;
          min-height: 0;
        }

        &__header {
          font-weight: 600;

          &__title {
            div:not(:last-child) {
              margin-bottom: 4px;
            }
          }

          span {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: rgba(79, 95, 114, 0.72);
          }
        }
      }
    }
  }
}

.popup-content.xgs-shipment-details__add-comment-modal-content {
  width: 400px !important;
}

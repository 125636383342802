@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

.xgs-image-viewer {
  &__controls {
    max-width: 100%;
    margin-bottom: $margin6;
    margin-top: -24px;
    display: flex;
    gap: 10px;
    row-gap: 16px;
    flex-wrap: wrap;

    &__clear-button-width {
      width: 155px;
    }

    &__selectall-button-width {
      width: 115px;
    }

    &__download-button-width {
      width: 186px;

      &__grey-icon {
        margin-right: 8px;
      }

      &__normal-icon {
        color: white;
        margin-right: 8px;
      }
    }

    &__item {
      //max-width: 100%;
      flex: 1 1 0px;
      margin-right: 16px;

      @include up-to(600px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &__image-styles {
    transform: rotate(90deg) !important;
    transition: transform 0.3s ease;
  }

  &__list__filters {
    display: flex;
    margin-bottom: 16px;
    max-width: 100%;
    align-items: flex-end;

    @include up-to(600px) {
      flex-wrap: wrap;
    }

    &__input {
      flex: 1 1 0px;
      margin-right: 16px;

      @include up-to(600px) {
        width: calc(50% - 8px);
        margin-bottom: 8px;

        &:nth-child(2) {
          margin-right: 0;
        }
      }
    }

    &__dates {
      flex: 2 1 0px;


      @include up-to(600px) {
        width: 100%;
        max-width: none;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &__button {
      &--last {
        margin-left: 16px;

        @include up-to(600px) {
          margin-left: 0;
          margin-top: 16px;
        }
      }

      @include up-to(600px) {
        width: 100%;
      }
    }
  }

  &__enabled_delete_icon_color {
    color: rgb(232, 42, 43);
    /* Default color */
  }

  &__disabled_delete_icon_color {
    color: rgba(79, 95, 114, 0.32);
    /* Disabled color */
  }

  &__checkbox-group {
    display: flex;
    align-items: center;
  }

  &__checkboxes {
    display: flex;
    gap: 20px;

    label {
      font-size: 16px;
    }

    input {
      width: 20px;
    }
  }

  &__restore-deleted {
    position: absolute;
    top: 55px;
    right: 16px;

    @include up-to(1360px) {
      left: auto;
      right: 16px;
    }

    @include up-to(768px) {
      right: 23px;
    }

    @include up-to(400px) {
      position: relative;
      top: auto;
      right: auto;
      margin-bottom: 24px;
      text-align: center;
    }

    &__spinner {
      margin-left: -4px !important;
    }

    &__margin-right-eight {
      margin-right: 8px;
    }

    &__child {
      height: 20px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &__small-button-delete {
    color: rgb(232, 42, 43);
    font-size: 16px;
    gap: 8px;

    img,
    svg {
      margin-right: $margin8;
    }

    &--grey {
      color: rgb(180, 18, 18);
    }
  }

  &__restore-icon {
    width: 28px;
    height: 14px;
    left: -27px;
    top: 2px;
    position: absolute;
    fill: red;
  }

  &__images-view-section {
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__thumbnail-modal {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 82px;

    &__probill-number-selector {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;

      &__probill-number-primary {
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        display: flex;
      }

      &__probill-number-secondary {
        justify-content: flex-start;
        align-items: center;
        display: flex;
      }

      &__probill-number-third {
        color: rgba(79, 95, 114, 0.72);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        word-wrap: break-word;
      }

      &__probill-number-fourth {
        color: black;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        word-wrap: break-word;
      }
      &__gap {
        flex: 1 1 0;
        height: 0px;
        border: 1px solid rgba(79, 95, 114, 0.56);
      }

      &__selector {
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: flex;
      }
    }

    &__thumbnail-card {
      padding: 4px;
      background: rgba(79, 95, 114, 0.04);
      border-radius: 4px;
      overflow: hidden;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      display: inline-flex;

      &__image {
        width: 80px;
        height: 108px;
        transition: filter 0.3s ease;
      }

      &__primary {
        flex: 1 1 0;
        align-self: stretch;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 8px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        display: inline-flex;
      }

      &__secondary {
        align-self: stretch;
        height: 48px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
      }

      &__third {
        align-self: stretch;
        justify-content: space-between;
        align-items: stretch;
        display: inline-flex;
      }

      &__fourth {
        flex: 1 1 0;
        height: 20px;
        justify-content: space-between;
        align-items: stretch;
        display: flex;
      }

      &__fifth {
        justify-content: flex-start;
        align-items: stretch;
        gap: 4px;
        display: flex;
      }

      &__sixth {
        justify-content: flex-start;
        align-items: stretch;
        gap: 50px;
        display: flex;
      }

      &__seventh {
        position: relative;
        width: 35px;
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        display: flex;
        height: 100%;
      }

      &__eighth {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 16px;
        word-wrap: break-word;
      }

      &__ninth {
        position: absolute;
        top: 50%;
        left: 110%;
        transform: translateY(-50%);
        padding: 5px;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        font-size: 10px;
        white-space: nowrap;
        z-index: 10;
      }

      &__tenth {
        color: rgba(79, 95, 114, 0.72);
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 20px;
        word-wrap: break-word;
        align-self: center;
      }


      &__eleventh {
        align-self: stretch;
        display: flex;
        align-items: center;

        &__checkbox {
          height: 100%;
          align-self: center;
        }
      }

      &twelfth {
        color: rgba(79, 95, 114, 0.92);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        word-wrap: break-word;
      }

      &__trash-imagedetails {
        flex: 1 1 0;
        align-self: stretch;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        display: flex;

        &__primary {
          align-self: stretch;
          height: 20px;
          justify-content: flex-end;
          align-items: flex-start;
          display: flex;
        }

        &__secondary {
          flex: 1 1 0;
          height: 20px;
          justify-content: space-between;
          align-items: flex-start;
          display: flex;
        }

        &__third {
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          display: flex;
        }

        &__fourth {
          color: rgba(79, 95, 114, 0.72);
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 20px;
          word-wrap: break-word;
        }

        &__fifth {
          margin-right: 8px;
          color: rgba(79, 95, 114, 1);
        }

        &__sixth {
          height: 100%;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          display: inline-flex;
          cursor: pointer;
        }
      }
    }
  }

  &__image-selections {
    position: fixed;
    bottom: 20px;
    left: 46%;
    transform: translateX(-28%);
    padding: 8px;
    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.20);
    border-radius: 4px;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;

    &__primary {
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      display: flex;
    }

    &__selected-docs {
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      display: flex;
      width: max-content;

      &__primary {
        color: black;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        word-wrap: break-word;
      }
    }

    &__buttons {
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      display: flex;
    }
  }
}

.xgs-image-view-indexer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-bottom: 135px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;

  &__header {
    align-self: stretch;
    height: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;

    &__primary {
      align-self: stretch;
      justify-content: space-between;
      align-items: center;
      display: inline-flex;
    }

    &__header-name {
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      display: flex;

      &__content {
        color: black;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
      }
    }

    &__i-icon {
      position: relative;
      display: inline-flex;
      align-items: center;

      &__primary {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__secondary {
        position: absolute;
        right: 100%;
        background: #2F80ED;
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__indexed-image-buttons {
    align-self: stretch;
    height: 593px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;

    &__indexed-on-by-primary {
      align-self: stretch;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      display: inline-flex;

      &__probill-customer {
        width: 233px;
      }
    }

    &__secondary {
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      display: flex;
    }

    &__third {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
    }

    &__fourth {
      color: rgba(79, 95, 114, 0.72);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }

    &__fifth {
      color: black;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }

    &__collections {
      align-self: stretch;
      justify-content: space-between;
      align-items: flex-end;
      display: inline-flex;

      &__primary {
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: flex;
      }

      &__download-copy-icon {
        color: #2F80ED;
        margin-right: 8px;
      }

      &_margin-right {
        margin-right: $margin8;
      }

      &__margin-left {
        margin-left: $margin8;
      }

      &__related-toggle {
        align-items: center;
        display: flex;

        &__name {
          margin-left: $margin8;
          font-size: 14px;
        }
      }

      &__reject {
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: flex;
      }
    }
  }

  &__related-original-images {
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;

    &__related {
      width: 79px;
      height: 588px;
      background: rgba(79, 95, 114, 0.08);
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid rgba(79, 95, 114, 0.32);
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      overflow-y: auto;
      overflow-x: hidden;

      &__original {
        align-self: stretch;
        height: 127px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        cursor: pointer;
        position: relative;

        &__img-primary {
          padding: 8px;
          align-self: stretch;
          box-sizing: border-box;
        }

        &__img-secondary {
          height: 100%;
          width: 100%;
          transition: filter 0.3s ease;
          box-sizing: border-box;
          border-radius: 4px;
        }

        &__tick-primary {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 20px;
          height: 20px;
          background: #2F80ED;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
        }

        &__tick-secondary {
          width: 12px;
          height: 12px;
          position: relative;
        }

        &__tick-third {
          color: white;
          margin-bottom: 2px;
        }

        &__id-type {
          align-self: stretch;
          text-align: center;
          color: black;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          word-wrap: break-word;
        }
      }

      &__related-label {
        align-self: stretch;
        text-align: center;
        color: rgba(79, 95, 114, 0.72);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        word-wrap: break-word;
      }

      &__spinner-loading {
        text-align: center;
        margin-top: 16px;
      }

      &__thumbnails {
        align-self: stretch;
        height: 127px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        cursor: pointer;

        &__image {
          height: 100%;
          width: 100%;
          transition: filter 0.3s ease;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
    }
  }

  &__image-container {
    flex: 1;
    height: 588px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: rgba(79, 95, 114, 0.08);
    border-radius: 4px;
    border: 1px solid rgba(79, 95, 114, 0.32);
    align-items: flex-start;
    display: flex;
    position: relative;
    overflow: hidden;

    &__id-primary {
      padding: 3px;
      background: rgba(0, 0, 0, 0.24);
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      display: flex;
      z-index: 2;
    }

    &__id-secondary {
      text-align: center;
      color: white;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      word-wrap: break-word;
    }

    &__primary {
      overflow: visible; // Prevents image from going outside the div
      position: relative; // Allows absolute positioning of the image
      width: 430px;
      height: 556px;
      padding: 8px;
      z-index: 1;
    }

    &__loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__img-controls {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      display: inline-flex;
      z-index: 2;
      position: relative;

      &__anticlock-primary {
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.40); // Example: Changes the background color on hover
          transform: scale(1.1); // Example: Slightly enlarges the element
          transition: transform 0.2s ease, background-color 0.2s ease; // Smooth transition for hover effects
        }
      }

      &__anticlock-secondary {
        width: 14px;
        height: 14px;
        position: relative;
      }

      &__anticlock-icon {
        width: 16.19px;
        height: 14px;
        left: -2.10px;
        top: 0px;
        position: absolute;
      }

      &__clock-plus-minus-primary {
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.40); // Example: Changes the background color on hover
          transform: scale(1.1); // Example: Slightly enlarges the element
          transition: transform 0.2s ease, background-color 0.2s ease; // Smooth transition for hover effects
        }
        
        &_clock-secondary {
          width: 16.19px;
          height: 14px;
          left: 0.71px;
          top: 0px;
          position: absolute;
        }

        &__plus-minus-secondary {
          width: 12px;
          height: 12px;
          left: 1px;
          top: 1px;
          position: absolute;
        }
      }

      &__plus-minus-icon {
        margin-top: 326px;
      }

      &__plus-minus {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        display: flex;
      }
    }
  }

  &__img-details-two {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;
  }

  &__img-details-insertion-buttons {
    align-self: stretch;
    height: 56px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;
    padding-bottom: 208px;

    &__button-primary {
      width: 741px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      display: inline-flex;

      &__tick-icon {
        color: white;
        margin-right: 8px;
      }

      &__pass {
        width: 138px;
      }
    }
  }
}
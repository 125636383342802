@import "./../../../sass/variables.module.scss";
@import "./../../../sass/mixins.scss";

$selectedBarMinWidth: 1220px;

.xgs-aging-invoices {
  @include responsiveTable(1508px);
  
  &__header {
    &__item {
      display: flex;
      gap: 24px;

      @include mobile() {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;

        .xgs-aging-invoices__header__total {
          display: none;
        }
      }
    }

    &__total {
      min-width: 176px;
      height: 56px;
      flex: 0 0 0;
    }
  }

  &__filter {
    @include mobile() {
      width: 100%;
    }
    
    .xgs-labeled-input {
      width: 186px;
      height: 74px;

      @include up-to(570px) {
        width: 100%;
      }
    }

    .xgs-list__controls__search {
      width: 100%;
      padding: 0;
      align-items: flex-start;
  
      .xgs-list__controls__button {
        margin-top: 24px;
        
        @include up-to(570px) {
          width: 100%;
          margin-top: 0;
        }
      }
    }
  }  

  &__selected-bar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    .xgs-table__selected-bar {
      &__content {
        @include up-to($selectedBarMinWidth) {
          flex-direction: column;
        }
      }

      &__item {
        @include up-to($selectedBarMinWidth) {
          width: 100%;
        }
      }
    }    

    &__warning {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }

    &__pay-button {
      flex: 1 1 auto;
    }
  }

  &__mobile-hidden {
    @include up-to($selectedBarMinWidth) {        
      display: none;        
    }
  }
}
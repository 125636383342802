@import "../../../sass/variables.module.scss";
@import "../../../sass/mixins.scss";

.xgs-pu-summary {

  &__tiles-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__empty {
    margin-top: $margin7;
  }


  &__tile {
    border-radius: 4px 4px 0px 0px;
    max-width: 600px;
    flex: 1 1 480px;

    @include up-to(920px) {
      min-width: 300px;
      flex: auto;
    }

    &__header {
      display: flex;
      height: 72px;
      padding: 24px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      border: 1px solid rgba(79, 95, 114, 0.32);
      font-weight: 600;

      @include up-to(600px) {
        cursor: pointer;
      }
      
      &__button {
        margin-left: auto;
        
        @include up-to(600px) {
          display: none;
        }
      }
    }

    &__body {
      display: flex;
      padding: 24px;
      gap: 48px;
      align-self: stretch;
      border-radius: 0px 0px 4px 4px;
      border: 1px solid rgba(79, 95, 114, 0.32);
      border-top: 0px;
      
      div.td {
        text-align: right;
      }
      &__button {
        margin-top: $margin6;

        button {
          width: 100%;
        }
      }

      &--small {
        display: none;
      }
  
      @include up-to(600px) {
        flex-wrap: wrap;

        &--small {
          display: block;
        }

        &--large {
          display: none;
        }
      }

      div[role = "row"] {
        height: 32px !important;
      }
      
      div[role="rowgroup"] {

        > * {
          height: 32px;
          align-items: center;
          text-align: right;
        }
        
        > :nth-child(even) {
          background-color: $gray7;
        }
      }
  
      &__block {
        width: 100%;

        .xgs-labeled-value {
          width: 100%;
          margin-bottom: $margin8;
          label {
            color: rgba(79, 95, 114, 0.72);
          }
          span {
            margin-left: auto;
          }
        }

        &--second {
          position: relative;
          
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -24px;
            height: 100%;
            width: 1px;
            background-color:#4F5F7252;

            @include up-to(600px) {
              height: 1px;
              width: 100%;
              left: 0;
              top: -26px;
            }
          }
        }
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background: rgba(47, 128, 237, 0.20);
    }
  }
}
